import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

export function Countdown({ countdownTime }: { countdownTime: number }) {
  const [time, setTime] = useState(countdownTime / 1000)
  let timeout = useRef<ReturnType<typeof setTimeout>>().current

  useEffect(() => {
    if (time > 0) {
      timeout = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [time])

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        left: 20,
        bottom: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: 120,
          height: 120,
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography color="white" variant="h1">
          {time}
        </Typography>
      </Box>
    </Box>
  )
}
