'use client'
import {
  Alert as MuiAlert,
  AlertTitle,
  Fade,
  Snackbar,
  Link,
  alertClasses,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { alertState, AlertType, defaultAlert } from './useAlert'

export function Alert() {
  const [message, setMessage] = useAtom(alertState)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }
  const handleTransitionEnd = () => {
    if (!isOpen) {
      setMessage(defaultAlert)
    }
  }

  useEffect(() => {
    if (message.isOpen) {
      setIsOpen(true)
    }
  }, [message])

  return (
    <Snackbar
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleClose}
      onTransitionEnd={handleTransitionEnd}
      open={isOpen}
      transitionDuration={500}
    >
      <MuiAlert
        sx={{
          [`& .${alertClasses.action}`]: {
            padding: 0,
            margin: 0,
            alignItems: 'center',
          },
        }}
        {...(!message.action && { onClose: handleClose })}
        severity={message.type ?? AlertType.INFO}
        {...(message.action && {
          action: (
            <Link
              onClick={() => {
                message.action?.onClick()
                handleClose()
              }}
              sx={{ cursor: 'pointer' }}
            >
              {message.action.label}
            </Link>
          ),
        })}
      >
        {message.title ? <AlertTitle>{message.title}</AlertTitle> : null}
        {message.text}
      </MuiAlert>
    </Snackbar>
  )
}
