'use client'
import { useAtomValue } from 'jotai'
import type { ReactNode } from 'react'
import { isPageLoadingState } from './state'
import { PageLoading } from './PageLoading'

export function PageLoadingProvider({ children }: { children: ReactNode }) {
  const { isLoading, hideSpinner, message } = useAtomValue(isPageLoadingState)
  return (
    <>
      {children}
      {isLoading ? (
        <PageLoading label={message} withSpinner={!hideSpinner} />
      ) : null}
    </>
  )
}
