'use client'
import { useThrottledCallback } from 'use-debounce'
import { useRef } from 'react'
import { useSetAtom } from 'jotai'
import { saveState } from './state'

export const useSaveCallback = <T>(
  action: (value: T) => Promise<void> | void
) => {
  const setOpen = useSetAtom(saveState)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const timeout = 1000
  const notificationDelay = timeout + timeout / 2

  const callback = async (value: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setOpen(false)
    }, notificationDelay)

    setOpen(true)

    await action(value)
  }
  const throttled = useThrottledCallback(callback, timeout, {
    leading: false,
    trailing: true,
  })

  return { save: throttled, flush: throttled.flush }
}
