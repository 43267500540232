'use client'

import { Edit } from '@mui/icons-material'
import { Box, Button, DialogActions, Typography } from '@mui/material'
import { useState } from 'react'
import { HtmlString } from '../HtmlString'
import { Popup } from '../Popup'
import { RichTextEditor } from '../RichTextEditor'

export function ClickToEditText({
  error,
  value,
  truncate,
  maxLength,
  label,
  onChange,
  placeholder,
  errorMinThreshold = 60,
  warningMinThreshold = 75,
  warningMaxThreshold = 100,
  errorMaxThreshold = 120,
}: {
  label?: string
  placeholder?: string
  error?: boolean
  value: string
  truncate?: boolean
  maxLength?: number
  onChange: (value: string) => void
  errorMinThreshold?: number
  warningMinThreshold?: number
  warningMaxThreshold?: number
  errorMaxThreshold?: number
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      sx={{
        cursor: 'pointer',
        border: (theme) =>
          `1px dashed ${error ? theme.palette.error.main : '#ccc'}`,
        borderRadius: 1,
        position: 'relative',
        minHeight: 100,
        p: 0.5,
      }}
    >
      {!value && (
        <Typography
          fontStyle="italic"
          sx={{ color: (theme) => theme.palette.grey[500] }}
          variant="body2"
        >
          {placeholder}
        </Typography>
      )}
      <HtmlString
        content={value}
        truncate={truncate}
        {...(maxLength && { maxLength })}
      />
      <Box
        onClick={() => {
          setIsOpen(true)
        }}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.5)',
          borderRadius: 1,
          opacity: 0,
          transition: 'opacity 0.3s ease-in',
          '&:hover': { opacity: 1 },
        }}
      >
        <Edit htmlColor="#fff" sx={{ fontSize: 50 }} />
      </Box>
      <Popup
        maxWidth="xl"
        onClose={() => {
          setIsOpen(false)
        }}
        open={isOpen}
        title={label}
      >
        <Box
          sx={{
            minWidth: {
              xs: '99vw',
              sm: '90vw',
              md: '80vw',
              lg: '60vw',
              xl: '50vw',
            },
          }}
        >
          <RichTextEditor
            autoresize={false}
            errorMaxThreshold={errorMaxThreshold}
            errorMinThreshold={errorMinThreshold}
            onChange={onChange}
            value={value}
            warningMaxThreshold={warningMaxThreshold}
            warningMinThreshold={warningMinThreshold}
          />
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
            size="large"
            variant="contained"
          >
            Done
          </Button>
        </DialogActions>
      </Popup>
    </Box>
  )
}
