'use client'
import type { LinkProps } from '@mui/material/Link'
import MuiLink from '@mui/material/Link'
import NextLink from 'next/link'
import type { ReactNode } from 'react'

export function Link({
  children,
  ...props
}: LinkProps & { children?: ReactNode }) {
  return (
    <MuiLink component={NextLink} {...props}>
      {children}
    </MuiLink>
  )
}
