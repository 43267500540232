export class BaseError extends Error {
  originalError?: unknown
  meta?: Record<string, unknown>

  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message)
    this.name = this.constructor.name
    this.meta = meta
    this.originalError = originalError
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      ...(this.meta && { meta: JSON.stringify(this.meta) }),
      ...(this.originalError instanceof Error && {
        originalError: JSON.stringify(this.originalError),
      }),
    }
  }
}

export class EnvironmentError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    const newMeta = {
      ...meta,
      env: process.env,
    }
    super(message, newMeta, originalError)
  }
}

export class AuthenticationError extends BaseError {
  constructor(message: string, originalError?: unknown) {
    super(message, {}, originalError)
    this.name = 'AuthenticationError'
  }
}

export class NotFoundError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'NotFoundError'
  }
}
export class BadRequestError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'BadRequestError'
  }
}
export class WebhookError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'WebhookError'
  }
}
export class GoogleClientError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'GoogleClientError'
  }
}
export class FileError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'FileError'
  }
}
export class CloudinaryError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'CloudinaryError'
  }
}

export class ModelError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'ModelError'
  }
}

export class PublishingValidationError extends BaseError {
  constructor(
    message: string,
    meta?: Record<string, unknown>,
    originalError?: unknown
  ) {
    super(message, meta, originalError)
    this.name = 'PublishingValidationError'
  }
}

export class ValidationError extends BaseError {
  constructor(errors: Record<string, string>, originalError?: unknown) {
    super('Error while processing input payload', { errors }, originalError)
    this.name = 'ValidationError'
  }
}
