'use client'

import { useEffect } from 'react'
import { GoogleClientError } from 'utils'
import { ScriptStatus, useInjectScript } from './useInjectScript'

const allowedMimeTypes = [
  'application/pdf',
  'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet',
  'video/mp4',
  'audio/mp4',
  'video/quicktime',
  'audio/x-wav',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/webp',
]

const createPBLWorksView = () => {
  if (!process.env.NEXT_PUBLIC_SHARED_CURRICULUM_FOLDER) {
    throw new GoogleClientError(
      'Missing process.env.NEXT_PUBLIC_SHARED_CURRICULUM_FOLDER'
    )
  }

  const view: google.picker.DocsView & { setLabel?: (label: string) => void } =
    new google.picker.DocsView(google.picker.ViewId.DOCS)
  view.setMimeTypes(allowedMimeTypes.join(','))
  view.setParent(process.env.NEXT_PUBLIC_SHARED_CURRICULUM_FOLDER)
  view.setIncludeFolders(true)
  view.setMode(google.picker.DocsViewMode.LIST)

  // setLabel is unofficial so we should be extra careful
  if (typeof view.setLabel === 'function') {
    view.setLabel('All files')
  }

  return view
}

export const useDrivePicker = (
  onDocumentSelected: (doc: google.picker.DocumentObject, token: string) => void
) => {
  const apiStatus = useInjectScript('https://apis.google.com/js/api.js')

  useEffect(() => {
    if (apiStatus === ScriptStatus.READY) {
      gapi.load('client:picker', () => {
        void gapi.client.load(
          'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
        )
      })
    }
  }, [apiStatus])

  const showPicker = (token: string) => {
    createPicker(token)
  }

  const createPicker = (token: string) => {
    if (!process.env.NEXT_PUBLIC_GOOGLE_DEVELOPER_KEY) {
      throw new GoogleClientError(
        'Could not find NEXT_PUBLIC_GOOGLE_DEVELOPER_KEY'
      )
    }
    if (!process.env.NEXT_PUBLIC_GOOGLE_APP_ID) {
      throw new GoogleClientError('Could not find NEXT_PUBLIC_GOOGLE_APP_ID')
    }

    const picker = new google.picker.PickerBuilder()
      .setDeveloperKey(process.env.NEXT_PUBLIC_GOOGLE_DEVELOPER_KEY)
      .setAppId(process.env.NEXT_PUBLIC_GOOGLE_APP_ID)
      .setTitle('Published Curriculum')
      .setOAuthToken(token)
      .addView(createPBLWorksView())
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setCallback((data) => {
        if (data.action === google.picker.Action.PICKED) {
          const document = data[google.picker.Response.DOCUMENTS][0]
          onDocumentSelected(document, token)
        }
      })
      .build()
    picker.setVisible(true)
  }

  return { showPicker }
}
