import { Stack, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import type { ReactNode } from 'react'

export function PageLoading({
  label,
  children,
  withSpinner = true,
}: {
  label?: string
  withSpinner?: boolean
  children?: ReactNode
}) {
  return (
    <Backdrop
      aria-hidden={false}
      open
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
    >
      <Stack alignItems="center" spacing={2}>
        {label ? <Typography>{label}</Typography> : null}
        {children}
        {withSpinner ? <CircularProgress color="inherit" /> : null}
      </Stack>
    </Backdrop>
  )
}
