import type { ClientResponse } from '@sendgrid/mail'
import mail from '@sendgrid/mail'

if (process.env.SENDGRID_API_KEY) {
  mail.setApiKey(process.env.SENDGRID_API_KEY)
}

type EmailData =
  | {
      email: string
      name: string
    }
  | string

export const sendTemplatedEmail: (params: {
  to: string
  from?: EmailData
  replyTo?: EmailData
  templateId: string
  dynamicTemplateData?: Record<string, unknown>
}) => Promise<[ClientResponse, Record<never, never>]> = async ({
  to,
  templateId,
  from = { email: 'crew@portmoto.com', name: 'Portmoto Crew' },
  dynamicTemplateData,
  replyTo,
}) => {
  return mail.send({
    to,
    from,
    replyTo,
    templateId,
    dynamicTemplateData,
  })
}

export const sendPlainTextEmail: (params: {
  to: string
  from?: EmailData
  replyTo?: EmailData
  text: string
}) => Promise<[ClientResponse, Record<never, never>]> = async ({
  to,
  text,
  from = { email: 'crew@portmoto.com', name: 'Portmoto Crew' },
  replyTo,
}) => {
  return mail.send({
    to,
    from,
    replyTo,
    text,
  })
}
