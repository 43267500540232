'use client'

import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import type { ReactNode } from 'react'
import { useRouter } from 'next/navigation'
import { Link } from '../Link'

export function ErrorHandler({
  error,
  reset,
  children,
}: {
  error: Error & { digest?: string }
  reset: () => void
  children?: ReactNode
}) {
  const router = useRouter()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      {children}
      <Alert severity="error">
        <AlertTitle>We are sorry but something went wrong</AlertTitle>
        {error.message}
        <Typography sx={{ display: 'block' }}>
          You can{' '}
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault()
              router.back()
            }}
          >
            go back
          </Link>{' '}
          or{' '}
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault()
              reset()
            }}
          >
            try again
          </Link>
          .
        </Typography>
      </Alert>
    </Box>
  )
}
