'use client'

import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

export const Swipe = forwardRef<
  Slider,
  {
    adaptiveHeight?: boolean
    children?: ReactNode
  }
>(function Swipe(props, ref) {
  return (
    <Slider
      adaptiveHeight={props.adaptiveHeight ?? true}
      arrows={false}
      dots={false}
      infinite={false}
      ref={ref}
      swipe={false}
    >
      {props.children}
    </Slider>
  )
})
