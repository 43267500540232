export const shorthandHTMLText = (input: string, length: number) => {
  if (input === '') {
    return null
  }

  const inputText = input
    .trim()
    // eslint-disable-next-line -- valid regexp to remove HTML
    .replace(/<\/?[^>]+(>|$)/g, '')
    // eslint-disable-next-line -- valid regexp to remove HTML
    .replace(/\&nbsp;/g, '')

  return `${inputText.substring(0, length)}${inputText.length > length ? '...' : ''}`
}
