import parser from 'html-react-parser'
import clip from 'text-clipper'
import xss from 'xss'

export function HtmlString({
  content,
  truncate = false,
  maxLength = 200,
}: {
  content: string
  truncate?: boolean
  maxLength?: number
}) {
  let htmlContent = xss(content)
  if (truncate) {
    htmlContent = clip(htmlContent, maxLength, {
      html: true,
    })
  }
  return <>{parser(htmlContent)}</>
}
