'use client'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import type { MouseEvent } from 'react'
import { useState } from 'react'

interface ConfirmBoxProps {
  open: boolean
  onProceed: () => void
  onCancel: () => void
  title?: string
  description: string
  proceedText?: string
  cancelText?: string
  typeToConfirm?: string
}

export function ConfirmBox({
  open,
  onProceed,
  onCancel,
  description,
  typeToConfirm,
  title = 'Are you sure you want to continue?',
  proceedText = 'Yes',
  cancelText = 'No',
}: ConfirmBoxProps) {
  const [confirmInputText, setConfirmInputText] = useState('')

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={(event: MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        onCancel()
      }}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" variant="h4">
        {title}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {description}
          </DialogContentText>
          {typeToConfirm ? (
            <TextField
              label="Type to confirm"
              onChange={(e) => {
                setConfirmInputText(e.target.value)
              }}
              value={confirmInputText}
              variant="outlined"
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            onCancel()
          }}
        >
          {cancelText}
        </Button>
        <Button
          color="primary"
          disabled={typeToConfirm ? confirmInputText !== typeToConfirm : false}
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            onProceed()
          }}
          variant="contained"
        >
          {proceedText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
