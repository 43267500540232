'use client'
import { useEffect, useState } from 'react'

export enum ScriptStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
}

export const useInjectScript = (src: string) => {
  const [status, setStatus] = useState(
    src ? ScriptStatus.LOADING : ScriptStatus.IDLE
  )
  useEffect(() => {
    if (!src) {
      setStatus(ScriptStatus.IDLE)
      return
    }

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`
    )
    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.setAttribute('data-status', ScriptStatus.LOADING)
      document.body.appendChild(script)

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        script?.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        )
      }
      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.getAttribute('data-status') as ScriptStatus)
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR)
    }

    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    return () => {
      script.removeEventListener('load', setStateFromEvent)
      script.removeEventListener('error', setStateFromEvent)
    }
  }, [src])
  return status
}
