import { Box, CircularProgress, Typography } from '@mui/material'

interface Props {
  minHeight: string
}

export function LoadingPlaceholder({ minHeight = '150px' }: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderColor: 'divider',
      }}
    >
      <CircularProgress sx={{ mb: 3 }} />
      <Typography>Loading the text editor...</Typography>
    </Box>
  )
}
