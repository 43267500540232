import { CircularProgress, Stack, Typography } from '@mui/material'

export function RecorderOverlayMessage({
  text,
  isLoading = false,
}: {
  text: string
  isLoading?: boolean
}) {
  return (
    <Stack
      spacing={1}
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : null}
      <Typography
        sx={{
          color: 'white',
          textAlign: 'center',
          maxWidth: '75%',
        }}
        variant="body2"
      >
        {text}
      </Typography>
    </Stack>
  )
}
