import { Done, Pause, RadioButtonChecked, Upload } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { RecordingStatus } from './status'

function UploadButton({
  onChooseFile,
}: {
  onChooseFile: (file: File) => void
}) {
  return (
    <>
      <input
        accept="video/*"
        id="button-file"
        onChange={(event) => {
          event.target.files && onChooseFile(event.target.files[0])
        }}
        style={{ display: 'none' }}
        type="file"
      />
      <label htmlFor="button-file">
        <Button
          color="secondary"
          component="span"
          fullWidth
          size="small"
          startIcon={<Upload />}
          variant="contained"
        >
          Upload Video
        </Button>
      </label>
    </>
  )
}

interface RecorderControlsProps {
  recordingStatus: RecordingStatus
  onClickRecordButton?: () => void
  onClickStopButton?: () => void
  onChooseFile?: (file: File) => void
}

export function RecorderControls({
  recordingStatus,
  onClickRecordButton,
  onClickStopButton,
  onChooseFile,
}: RecorderControlsProps) {
  const areControlsVisible = ![
    RecordingStatus.COUNTDOWN,
    RecordingStatus.LOADING,
  ].includes(recordingStatus)

  return (
    <>
      {areControlsVisible ? (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack
            direction={
              recordingStatus === RecordingStatus.NOT_STARTED ? 'column' : 'row'
            }
            spacing={1}
          >
            <Button
              color="secondary"
              onClickCapture={onClickRecordButton}
              size="small"
              startIcon={
                recordingStatus === RecordingStatus.RECORDING ? (
                  <Pause />
                ) : (
                  <RadioButtonChecked color="error" />
                )
              }
              variant="contained"
            >
              {recordingStatus === RecordingStatus.NOT_STARTED &&
                'Start Recording'}
              {recordingStatus === RecordingStatus.RECORDING && 'Pause'}
              {recordingStatus === RecordingStatus.PAUSED && 'Resume'}
              {recordingStatus === RecordingStatus.STOPPED && 'Record Again'}
            </Button>
            {recordingStatus === RecordingStatus.NOT_STARTED && onChooseFile ? (
              <UploadButton onChooseFile={onChooseFile} />
            ) : null}
            {[RecordingStatus.RECORDING, RecordingStatus.PAUSED].includes(
              recordingStatus
            ) && (
              <Button
                color="secondary"
                onClick={onClickStopButton}
                size="small"
                startIcon={<Done color="success" />}
                variant="contained"
              >
                Finish
              </Button>
            )}
          </Stack>
        </Box>
      ) : null}
    </>
  )
}
