import { atom, useSetAtom } from 'jotai'

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface AlertAction {
  label: string
  onClick: () => void
}
export interface AlertPayload {
  text: string | JSX.Element
  isOpen: boolean
  title?: string
  type?: AlertType
  action?: AlertAction
}

export const defaultAlert: AlertPayload = {
  text: '',
  title: '',
  isOpen: false,
  type: AlertType.INFO,
  action: undefined,
}

export const alertState = atom<AlertPayload>(defaultAlert)

export const useAlert = () => {
  const setMessage = useSetAtom(alertState)

  return {
    info: (
      text: string | JSX.Element,
      title?: string,
      action?: AlertAction
    ) => {
      setMessage({ isOpen: true, type: AlertType.INFO, title, text, action })
    },
    error: (
      text: string | JSX.Element,
      title?: string,
      action?: AlertAction
    ) => {
      setMessage({ isOpen: true, type: AlertType.ERROR, title, text, action })
    },
    success: (
      text: string | JSX.Element,
      title?: string,
      action?: AlertAction
    ) => {
      setMessage({ isOpen: true, type: AlertType.SUCCESS, title, text, action })
    },
    warning: (
      text: string | JSX.Element,
      title?: string,
      action?: AlertAction
    ) => {
      setMessage({ isOpen: true, type: AlertType.WARNING, title, text, action })
    },
  }
}
