export const style = [
  `
body { font-family: 'Roboto', Helvetica, Arial, sans-serif; font-size:14px; }

.mce-content-body[data-mce-placeholder] {
position: relative;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
color: rgba(34, 47, 62, 0.7);
content: attr(data-mce-placeholder);
position: absolute;
}

.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
left: 1px;
}

.mce-content-body[dir=rtl][data-mce-placeholder]:not(.mce-visualblocks)::before {
right: 1px;
}

ul, ol {
  padding: 0;
  margin: 0;
  padding-left: 32px;
}`,
].join('\n')
