'use client'
import { Box, CardMedia } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { getBestSupportedMimeType, getExtensionFromMimeType } from 'utils'
import { Countdown } from './Countdown'
import { RecorderControls } from './RecorderControls'
import { RecorderOverlayMessage } from './RecorderOverlayMessage'
import { RecordingTime } from './RecordingTime'
import { RecordingStatus } from './status'

export interface VideoRecorderProps {
  disconnectedMessage?: string
  onRecordingFinished?: (file: File) => void
  timeLimit?: number
  width: number
  height: number
}

export function VideoRecorder({
  onRecordingFinished,
  timeLimit,
  width,
  height,
  disconnectedMessage,
}: VideoRecorderProps) {
  const player = useRef<HTMLVideoElement>(null)
  const recorder = useRef<MediaRecorder>()
  const chunks = useRef<Blob[]>([])
  const stream = useRef<MediaStream>()
  const [recordingStatus, setRecordingStatus] = useState(
    RecordingStatus.NOT_STARTED
  )

  useEffect(() => {
    switch (recordingStatus) {
      case RecordingStatus.STOPPED:
        if (player.current) {
          player.current.srcObject = null
          const mimeType = getBestSupportedMimeType()
          const ext = getExtensionFromMimeType(mimeType)
          const videoFile = new File(chunks.current, `video-recording.${ext}`, {
            type: mimeType,
          })
          player.current.src = URL.createObjectURL(videoFile)
          player.current.currentTime = 0
          onRecordingFinished?.(videoFile)
        }
        break
      default:
        break
    }
  }, [recordingStatus, onRecordingFinished])

  const createRecorder = () => {
    if (!stream.current) return
    const mimeType = getBestSupportedMimeType()
    recorder.current = new MediaRecorder(stream.current, {
      mimeType,
    })
    chunks.current = []

    recorder.current.addEventListener('start', () => {
      setRecordingStatus(RecordingStatus.RECORDING)
    })

    recorder.current.addEventListener('resume', () => {
      setRecordingStatus(RecordingStatus.RECORDING)
    })

    recorder.current.addEventListener('pause', () => {
      setRecordingStatus(RecordingStatus.PAUSED)
    })

    recorder.current.addEventListener('dataavailable', ({ data }) => {
      if (data.size > 0) {
        chunks.current.push(data)
      }
    })

    recorder.current.addEventListener('stop', () => {
      stream.current?.getTracks().forEach((track) => {
        track.stop()
      })
      setRecordingStatus(RecordingStatus.STOPPED)
    })
  }

  const startRecording = () => {
    createRecorder()
    recorder.current?.start()
  }

  const handleRecordingStart = async () => {
    if (!player.current) return
    setRecordingStatus(RecordingStatus.LOADING)
    stream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    })
    player.current.removeAttribute('src')
    player.current.srcObject = stream.current
    player.current.load()
    await player.current.play()
    setRecordingStatus(RecordingStatus.COUNTDOWN)
    setTimeout(() => {
      setRecordingStatus(RecordingStatus.RECORDING)
      startRecording()
    }, 3000)
  }

  const resumeRecording = () => {
    recorder.current?.resume()
  }

  const pauseRecording = () => {
    recorder.current?.pause()
  }

  const stopRecording = () => {
    if (
      ![RecordingStatus.RECORDING, RecordingStatus.PAUSED].includes(
        recordingStatus
      )
    ) {
      return
    }
    recorder.current?.stop()
  }

  const onClickRecordButton = async () => {
    switch (recordingStatus) {
      case RecordingStatus.NOT_STARTED:
      case RecordingStatus.STOPPED:
        await handleRecordingStart()
        break
      case RecordingStatus.RECORDING:
        pauseRecording()
        break
      case RecordingStatus.PAUSED:
        resumeRecording()
        break
      default:
    }
  }

  return (
    <Box sx={{ position: 'relative', borderRadius: 1 }}>
      <CardMedia
        autoPlay={false}
        component="video"
        controls={recordingStatus === RecordingStatus.STOPPED}
        muted
        playsInline
        ref={player}
        sx={{
          height,
          width,
          borderRadius: 1,
          objectFit: 'contain',
          background: 'rgb(0, 0, 0, 0.9)',
        }}
      />
      {disconnectedMessage &&
      recordingStatus === RecordingStatus.NOT_STARTED ? (
        <RecorderOverlayMessage text={disconnectedMessage} />
      ) : null}
      {recordingStatus === RecordingStatus.LOADING && (
        <RecorderOverlayMessage isLoading text="Please wait" />
      )}
      {[RecordingStatus.PAUSED, RecordingStatus.RECORDING].includes(
        recordingStatus
      ) && (
        <RecordingTime
          onTimeEnd={stopRecording}
          recordingStatus={recordingStatus}
          timeLimit={timeLimit}
        />
      )}
      <RecorderControls
        onClickRecordButton={() => void onClickRecordButton()}
        onChooseFile={onRecordingFinished}
        onClickStopButton={stopRecording}
        recordingStatus={recordingStatus}
      />
      {recordingStatus === RecordingStatus.COUNTDOWN && (
        <Countdown countdownTime={3000} />
      )}
    </Box>
  )
}
