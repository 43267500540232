import Close from '@mui/icons-material/Close'
import type { DialogProps } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import type { ReactElement } from 'react'

type Props = DialogProps & {
  popupTitle?: string | JSX.Element | ReactElement
}

export function Popup({ children, title, popupTitle, ...props }: Props) {
  return (
    <Dialog {...props}>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography flexGrow={1} variant="h4">
            {popupTitle ?? title ?? ''}
          </Typography>
          {props.onClose ? (
            <IconButton
              aria-label="close"
              onClick={(event) => props.onClose?.(event, 'escapeKeyDown')}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
