import { FiberManualRecord } from '@mui/icons-material'
import { Alert, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { RecordingStatus } from './status'

const pad = (unit: number) => {
  const str = unit.toString()
  const padReturn = '00'
  return padReturn.substring(0, pad.length - str.length) + str
}

const humanizeTime = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)

  const humanTime = `${pad(minutes)}:${pad(seconds - minutes * 60)}`

  return humanTime
}

export function RecordingTime({
  recordingStatus,
  timeLimit,
  onTimeEnd,
}: {
  recordingStatus: RecordingStatus
  timeLimit?: number
  onTimeEnd: () => void
}) {
  let timeout = useRef<ReturnType<typeof setTimeout>>().current
  const [currentTime, setCurrentTime] = useState(0)
  const [isWarningVisible, setIsWarningVisible] = useState(false)

  const stopTimer = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
  }

  const tick = () => {
    timeout = setTimeout(() => {
      setCurrentTime(currentTime + 100)
    }, 100)
  }

  useEffect(() => {
    if (recordingStatus === RecordingStatus.RECORDING) {
      tick()
    }

    if (typeof timeLimit !== 'undefined') {
      const diff = timeLimit - currentTime
      if (diff <= 0) {
        onTimeEnd()
        stopTimer()
        setIsWarningVisible(false)
      } else if (diff <= 30000) {
        setIsWarningVisible(true)
      } else if (isWarningVisible) {
        setIsWarningVisible(false)
      }
    }

    return () => {
      stopTimer()
    }
  }, [currentTime])

  useEffect(() => {
    switch (recordingStatus) {
      case RecordingStatus.PAUSED:
        stopTimer()
        break
      case RecordingStatus.STOPPED:
        stopTimer()
        setCurrentTime(0)
        break
      case RecordingStatus.RECORDING:
        tick()
        break
      default:
    }
  }, [recordingStatus])

  return (
    <Stack
      direction="row"
      justifyContent={isWarningVisible ? 'space-between' : 'flex-end'}
      sx={{
        position: 'absolute',
        top: 10,
        right: 10,
        left: 10,
      }}
    >
      {isWarningVisible ? (
        <Alert
          icon={false}
          severity="warning"
          sx={{ p: 0.5, lineHeight: 1.1, '& .MuiAlert-message': { p: 0 } }}
        >
          <Typography variant="caption">
            Stopping in {humanizeTime((timeLimit ?? 0) - currentTime)}
          </Typography>
        </Alert>
      ) : null}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={0.5}
        sx={{
          color: 'white',
        }}
      >
        <FiberManualRecord color="error" fontSize="small" />
        <Typography
          sx={{
            textShadow: '1px 1px #000000',
            fontFamily: 'monospace, monospace',
          }}
          variant="body2"
        >
          {humanizeTime(currentTime)}
        </Typography>
      </Stack>
    </Stack>
  )
}
