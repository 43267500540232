const videoTypes = ['webm', 'ogg', 'mp4', 'x-matroska']
// const audioTypes = ['webm', 'ogg', 'mp3', 'x-matroska']
const codecs = [
  'vp9',
  'vp9.0',
  'vp8',
  'vp8.0',
  'avc1',
  'av1',
  'h265',
  'h.265',
  'h264',
  'h.264',
  'opus',
  'pcm',
  'aac',
  'mpeg',
  'mp4a',
]

export const getBestSupportedMimeType = () => {
  const allSupported = getVideoTypesSupported()
  if (allSupported.length > 0) return allSupported[0]

  throw new Error('There is no MimeType supported for this device')
}

export const getExtensionFromMimeType = (mimeType: string) => {
  const type = mimeType.split('/')[1].split(';')[0]

  const extension = {
    webm: 'webm',
    ogg: 'ogg',
    mp4: 'mp4',
    'x-matroska': 'mkv',
  }[type]

  if (extension) return extension
  throw new Error(`Could not find the proper extension for ${mimeType}`)
}

export const getVideoTypesSupported = () => {
  const supported: string[] = []

  videoTypes.forEach((videoType) => {
    codecs.forEach((codec) => {
      const mime1 = `video/${videoType};codecs=${codec}`
      const mime2 = `video/${videoType};codecs=${codec.toUpperCase()}`
      if (MediaRecorder.isTypeSupported(mime1)) {
        supported.push(mime1)
      } else if (MediaRecorder.isTypeSupported(mime2)) {
        supported.push(mime2)
      }
    })
    if (MediaRecorder.isTypeSupported(`video/${videoType}`)) {
      supported.push(`video/${videoType}`)
    }
  })

  return supported
}
